import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import StyledHero from '../components/StyledHero';
import ContactForm from '../components/ContactForm';
import Map from '../components/Map';

const Yhteys = ({ data }) => {
  const { page_title, header, content } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <SEO title={page_title} />
      <StyledHero img={header.image.childImageSharp.fluid} />
      <ContactForm content={content[0]} />
      <Map content={content[1]} />
    </Layout>
  );
};

export default Yhteys;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        page_title
        header {
          image {
            childImageSharp {
              fluid(quality: 50, maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          banner_main
          banner_sub
          cta_1 {
            text
            url
          }
          cta_2 {
            text
            url
          }
        }
        cards {
          card {
            image {
              childImageSharp {
                fluid(quality: 90, maxWidth: 280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            cta {
              text
              url
            }
          }
        }
        content {
          title
          text
          cta {
            text
            url
          }
        }
      }
    }
  }
`;
