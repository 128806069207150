import React from 'react';
import styles from '../css/map.module.css';

const Map = ({ content }) => {
  return (
    <section className={styles.center}>
      <div className={styles.mapBox}>
        <h2>{content.title || 'Tai tule käymään'}</h2>
        <iframe
          className={styles.map}
          title="Jalokuusi OY kartalla"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1906.2762897705484!2d23.760642015937343!3d61.45914804549554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468f1ffcc5c1ff07%3A0xbf4fdb333cd6bc3f!2sJalokuusi+Oy!5e0!3m2!1sfi!2sfi!4v1545841854890"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
};

export default Map;
