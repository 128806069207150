import React, { Component } from 'react';
import styles from '../css/contact.module.css';

class ContactForm extends Component {
  state = {
    name: '',
    nameFocus: false,
    email: '',
    emailFocus: false,
    message: '',
    messageFocus: false
  };

  render() {
    const { title, cta } = this.props.content;

    return (
      <section className={styles.contact}>
        <h2>{title || 'Lähetä viesti'}</h2>
        <div className={styles.center}>
          <form
            className={styles.form}
            action="https://formspree.io/jalokuusi@jalokuusi.fi"
            method="POST"
          >
            <div>
              <label
                htmlFor="name"
                className={
                  this.state.nameFocus || this.state.name ? styles.focused : ''
                }
              >
                Nimi
              </label>
              <input
                type="name"
                name="name"
                id="name"
                required
                autoComplete="off"
                className={styles.formControl}
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
                onFocus={() => this.setState({ nameFocus: true })}
                onBlur={() => this.setState({ nameFocus: false })}
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className={
                  this.state.emailFocus || this.state.email
                    ? styles.focused
                    : ''
                }
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                required
                autoComplete="off"
                className={styles.formControl}
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                onFocus={() => this.setState({ emailFocus: true })}
                onBlur={() => this.setState({ emailFocus: false })}
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className={
                  this.state.messageFocus || this.state.message
                    ? styles.focused
                    : ''
                }
              >
                Viesti
              </label>
              <textarea
                name="message"
                id="message"
                rows="10"
                required
                autoComplete="off"
                className={styles.formControl}
                value={this.state.message}
                onChange={e => this.setState({ message: e.target.value })}
                onFocus={() => this.setState({ messageFocus: true })}
                onBlur={() => this.setState({ messageFocus: false })}
              />
            </div>
            <div className={styles.submit}>
              <input
                type="submit"
                value={cta.text || 'Lähetä'}
                className="btn-secondary"
              />
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default ContactForm;
